import React from "react";
import "./WhyUs.css";

export default function WhyUs() {
  return (
    <section id="why-us" className="why-us">
      <div className="why-us-container">
        <div className="why-us-content">
          <div className="why-us-section-title">
            <h2>Why Code Rhythm</h2>
            <h3>We teach you the code of life!</h3>
          </div>
          <p>
            Mind and Career Development: Code Rhythm goes beyond coding to
            nurture essential life skills, empowering you to make sound
            decisions, identify root causes of problems, and become a successful
            problem solver.
          </p>
          <p>
            Entrepreneurial Mindset: Our curriculum instills an entrepreneurial
            mindset, empowering you to create your own opportunities and venture
            into entrepreneurship with confidence.
          </p>
          <p>
            Community and Networking: Connect with like-minded individuals,
            industry experts, and mentors through our vibrant tech community,
            fostering personal and professional growth.
          </p>
          <p>
            Practical Learning: Participate in webinars, workshops, and talks
            focused on the intersection of technology, mental well-being,
            careers, and entrepreneurship, providing practical insights for
            real-world success.
          </p>
          <p>
            Join Code Rhythm and embark on a journey of personal and
            professional growth, where coding becomes a catalyst for a brighter
            future.
          </p>
          <a
            href="https://forms.gle/yk3QhrrxtYQ8T5f49"
            target={"_blank"}
            rel="noreferrer"
            className="button"
          >
            Enroll Now
          </a>
        </div>
        <a
          href="https://www.freepik.com/free-vector/web-development-programmer-engineering-coding-website-augmented-reality-interface-screens-developer-project-engineer-programming-software-application-design-cartoon-illustration_10798281.htm#fromView=search&page=1&position=12&uuid=399f9ab6-3b8d-4d42-8cd7-1a0ff3ed8a6a"
          target={"_blank"}
          rel="noreferrer"
        >
          <div className="why-us-image"></div>
        </a>
      </div>
    </section>
  );
}
