import React, { useEffect, useState } from "react";
import "./ContactForm.css";
import { startOfToday, format } from "date-fns";
import { auth, db } from "../../../firebaseConfig";
import { get, ref, set } from "firebase/database";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";

export default function ContactForm({
  setIsContactForm,
  setDataChoices,
  dataChoices,
}) {
  const [isFocus, setIsFocus] = useState("fname");

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    setPassword(email);
  }, [email]);

  const today = startOfToday();
  const todayFormatted = format(today, "EEE, do MMM yyyy");

  const monthFormatted = format(today, "MMM");

  const now = new Date();
  const timeFormatted = format(now, "hh:mm a");

  const handleSubmit = async (e) => {
    setIsSubmiting(true);
    e.preventDefault();

    const data = {
      ...dataChoices,
      contactDetails: {
        email: email,
        firstName: firstName,
        secondName: secondName,
        phoneNumber: phoneNumber,
      },
      date: todayFormatted,
      time: timeFormatted,
    };

    try {
      // Attempt to sign in the user first
      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Signed in successfully
          const user = userCredential.user;
          if (user) {
            const userRef = ref(db, `${monthFormatted}/` + user.uid);

            // Fetch current data once
            const snapshot = await get(userRef);
            const userData = snapshot.val();

            // Merge new data with existing data
            const updatedData = {
              ...userData,
              moreResponse: [...(userData.moreResponse || []), data],
            };
            // Update database with merged data
            await set(userRef, updatedData);

            alert("You successfully logged in! We will get in touch!");
            window.location.href = "/";
          }
        })
        .catch((signInError) => {
          // User does not exist, attempt to create a new account

          if (
            signInError.code === "auth/user-not-found" ||
            signInError.code === "auth/invalid-credential"
          ) {
            createUserWithEmailAndPassword(auth, email, password)
              .then(async (userCredential) => {
                // Signed up successfully
                const user = userCredential.user;
                if (user) {
                  const userRef = ref(db, `${monthFormatted}/` + user.uid);
                  await set(userRef, data);
                  alert("You successfully registered! We will get in touch!");
                  window.location.href = "/";
                }
              })
              .catch((createError) => {
                const createErrorCode = createError.code;
                const createErrorMessage = createError.message;
                console.error(
                  "Firebase Auth Create Error:",
                  createErrorCode,
                  createErrorMessage
                );
                alert(createErrorMessage); // Display error message to the user
              });
          } else {
            const signInErrorCode = signInError.code;
            const signInErrorMessage = signInError.message;
            console.error(
              "Firebase Auth Sign In Error:",
              signInErrorCode,
              signInErrorMessage
            );
            alert(signInErrorMessage); // Display error message to the user
          }
        })
        .finally(() => setIsSubmiting(false));
    } catch (error) {
      alert("Oops, a problem occurred");
      setIsSubmiting(false);
      console.error("Error:", error);
    }
  };

  return (
    <form className="contactform" onSubmit={handleSubmit}>
      <div className="input-group">
        <input
          className={isFocus === "fname" ? "focus" : ""}
          placeholder="First Name*"
          required
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          onFocus={() => setIsFocus("fname")}
        />
      </div>
      <div className="input-group">
        <input
          className={isFocus === "sname" ? "focus" : ""}
          placeholder="Second Name*"
          required
          type="text"
          onChange={(e) => setSecondName(e.target.value)}
          value={secondName}
          onFocus={() => setIsFocus("sname")}
        />
      </div>
      <div className="input-group">
        <input
          className={isFocus === "no" ? "focus" : ""}
          placeholder="Phone Number*"
          required
          onChange={(e) => setPhoneNumber(e.target.value)}
          value={phoneNumber}
          type="number"
          onFocus={() => setIsFocus("no")}
        />
      </div>
      <div className="input-group">
        <input
          className={isFocus === "email" ? "focus" : ""}
          placeholder="email"
          required
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type="email"
          onFocus={() => setIsFocus("email")}
        />
      </div>
      <button type="submit" disabled={isSubmiting}>
        {isSubmiting ? "SUBMITING..." : "SUBMIT"}{" "}
      </button>
      <button
        disabled={isSubmiting}
        type="button"
        onClick={() => setIsContactForm(false)}
        className="back"
      >
        Back
      </button>
    </form>
  );
}
