import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/home/Home";
import AboutPage from "./pages/about/AboutPage";
import Courses from "./pages/courses/Courses";
import Contact from "./pages/contact/Contact";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import RegistrationForm from "./pages/enrollment/RegistrationForm";
import Programmes from "./pages/programmes/Programmes";
import Payment from "./pages/payment/Payment";

export default function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/courses/:course" element={<Courses />} />
          <Route path="/programmes" element={<Programmes />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/enrollment" element={<RegistrationForm />} />
          <Route path="/payment/:name" element={<Payment />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
