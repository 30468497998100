import React from "react";
import { Link } from "react-router-dom";
import "./OfferedCourses.css";

export default function OfferedCourses() {
  return (
    <section id="courses" className="offered-courses">
      <div className="cta">
        <h2> Join us and embark on a transformative learning journey</h2>
        <h3>Goes beyond coding at Code Rhythm.</h3>
        <p>⭐️ Best way to transform your life.</p>
        <a href="https://forms.gle/yk3QhrrxtYQ8T5f49" className="button">
          Enroll Now
        </a>
      </div>
      <div className="offered-courses-container">
        <div className="row-1">
          <Link
            to={"/courses/fullspectrum"}
            className="row-1-container"
            style={{ textDecoration: "none" }}
          >
            <div className="class-title">
              <h2>Full Spectrum Tech Course</h2>
              <h3>Roadmap to Professional Tech Expertise</h3>
            </div>
          </Link>
        </div>
        <div className="row-2">
          <div className="row-2-col-1">
            <Link
              style={{ textDecoration: "none" }}
              to={"/courses/animation"}
              className="row-2-col-1-container"
            >
              <div className="class-title">
                <h2>Animation</h2>
                <h3>Learn Both 2D and 3D Animation</h3>
              </div>
            </Link>
          </div>
          <div className="row-2-col-2">
            <Link
              style={{ textDecoration: "none" }}
              to={"/courses/gaming"}
              className="row-2-col-2-container"
            >
              <div className="class-title">
                <h2>Gaming</h2>
                <h3>Start Building Games.</h3>
              </div>
            </Link>
          </div>
          <div className="row-2-col-3">
            <Link
              style={{ textDecoration: "none" }}
              to={"/courses/stem"}
              className="row-2-col-3-container"
            >
              <div className="class-title">
                <h2>STEM CONCEPTS</h2>
                <h3>Practical And Theoritical</h3>
              </div>
            </Link>
          </div>
        </div>
        <div className="row-3">
          <div className="row-3-col-1">
            <Link
              style={{ textDecoration: "none" }}
              to={"/courses/codedj"}
              className="row-3-col-1-container"
            >
              <div className="class-title">
                <h2>Code DJ</h2>
                <h3>Create And Mix Beats With Codes.</h3>
              </div>
            </Link>
          </div>
          <div className="row-3-col-2">
            <Link
              style={{ textDecoration: "none" }}
              to={"/courses/specialise"}
              className="row-3-col-2-container"
            >
              <div className="class-title">
                <h2>Specialized Tech Courses</h2>
                <h3>Focused Learning Path</h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
