import hero0 from "../../assets/heroImages/specialised_hero.png";
import hero1 from "../../assets/heroImages/fullspectrum_hero.png";
import hero2 from "../../assets/heroImages/webdevelopment_hero.png";
import hero3 from "../../assets/heroImages/animation.png";
import hero4 from "../../assets/heroImages/appdev_hero.png";
import hero5 from "../../assets/heroImages/backenddev_database_hero.png";
import hero6 from "../../assets/heroImages/c_hero.png";
import hero7 from "../../assets/heroImages/cloud_architecture_hero.png";
import hero8 from "../../assets/heroImages/cplusplus_hero.png";
import hero9 from "../../assets/heroImages/csharp_hero.png";
import hero10 from "../../assets/heroImages/cybersecurity_hero.png";
import hero11 from "../../assets/heroImages/deepdive_frontend_hero.png";
import hero12 from "../../assets/heroImages/dj_hero.png";
import hero13 from "../../assets/heroImages/foundation_hero.png";
import hero15 from "../../assets/heroImages/game_dev_hero.png";
import hero16 from "../../assets/heroImages/intro_cloud_architecture_hero.png";
import hero17 from "../../assets/heroImages/java_hero.png";
import hero18 from "../../assets/heroImages/ml_hero.png";
import hero19 from "../../assets/heroImages/prototyping_hero.png";
import hero20 from "../../assets/heroImages/software_enginnering.png";
import hero21 from "../../assets/heroImages/specialise_app_hero.png";
import hero22 from "../../assets/heroImages/3dwebsite_hero.png";
import hero23 from "../../assets/heroImages/stem_hero.png";
import hero24 from "../../assets/heroImages/uiux_hero.png";
import hero25 from "../../assets/heroImages/webdevelopment_hero.png";

export const coursesData = {
  specialise: {
    heroImg: hero0,
    name: "specialise",
    title: "Specialised Courses",
    price: 30000,

    description:
      "Specialized Tech Courses are designed to provide in-depth training in specific areas of technology, catering to students who wish to deepen their expertise and specialize in niche domains. These courses offer a focused curriculum that delves into advanced concepts, tools, and techniques, ensuring that participants gain a comprehensive understanding and practical skills needed to excel in their chosen field.",
    outline: [
      "Foundation in Programming Concepts and Web Development",
      "Deep Dive into Frontend Development",
      "Exploration of Backend Development and Databases",
      "Specialization in App Development",
      "Introduction to Cloud Architecture",
      "Data Analysis and Machine Learning",
      "Cybersecurity Fundamentals",
      "Prototyping and Design Principles",
    ],
  },
  fullspectrum: {
    heroImg: hero1,
    name: "fullspectrum",
    title: "Full Spectrum",
    price: 30000,

    description:
      "The Full Spectrum Tech Course serves as a comprehensive roadmap for individuals looking to embark on a journey into the world of technology. It provides a structured path through various domains of tech, ensuring learners acquire a well-rounded skill set to excel in the industry. This course is designed to take participants through a step-by-step progression, starting from foundational concepts and gradually advancing to more specialized areas.",
    outline: [
      "Website Development",
      "Mobile App development",
      "Data Analysis",
      "Machine Learning",
      "Cloud Architecture",
      "Cybersecurity Analysis",
      "Ui/Ux designing",
      "Full Stack Development",
      "Software Engineering",
    ],
  },
  webdevelopment: {
    heroImg: hero25,
    name: "webdevelopment",
    title: "Web Developer",
    duration: {
      classes: 25,
      weeks: 8,
    },
    price: {
      online: "KES 45,000",
      physical: "KES 51,000",
    },
    description:
      "This course equips students with the skills needed to design and develop dynamic websites. Over 25 classes, participants learn HTML, CSS, JavaScript, and other essential web technologies to create responsive and interactive web applications.",
    outline: [
      "Introduction to Web Development",
      "HTML Basics",
      "Advanced HTML",
      "CSS Fundamentals",
      "Responsive Web Design with CSS",
      "JavaScript Basics",
      "Intermediate JavaScript",
      "Advanced JavaScript",
      "DOM Manipulation",
      "Web APIs",
      "Introduction to Node.js",
      "Building a Server with Node.js",
      "Introduction to React",
      "State Management in React",
      "Building a React Application",
      "Version Control with Git",
      "Using GitHub for Project Hosting",
      "API Testing with Postman",
      "Deploying Web Applications",
      "Web Performance Optimization",
      "Introduction to Web Security",
      "Building Interactive Web Applications",
      "Final Project Planning",
      "Final Project Development",
      "Final Project Presentation",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "Visual Studio Code (or any preferred code editor)",
          "Latest version of a web browser (Chrome, Firefox, Safari, etc.)",
        ],
        "Languages and Libraries": [
          "HTML",
          "CSS",
          "JavaScript",
          "Node.js (latest LTS version)",
        ],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Postman (for API testing)",
          "Live Server extension for Visual Studio Code",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic computer operations.",
          "Basic understanding of web browsers and the internet.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },
  appdevelopment: {
    heroImg: hero4,
    name: "appdevelopment",
    title: "App Development",
    duration: { classes: 20, weeks: 6 },
    price: { online: 45000, physical: 51000 },
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System": {
            "For Android Development":
              "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
            "For iOS Development":
              "macOS 10.14 or later (Note: iOS development requires a Mac computer).",
          },
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
        "Additional Devices": {
          Smartphone: "An Android device for testing Android apps.",
          iPhone: "(Optional) for testing iOS apps.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "For Android Development: android Studio (latest version)\nJava Development Kit (JDK) (latest version)\nKotlin Plugin for Android Studio",

          "For iOS Development: Xcode (latest version)\nSwift (included with Xcode)",

          "For Cross-Platform Development: Node.js (latest LTS version)\nReact Native CLI or Expo CLI\nVisual Studio Code or any preferred code editor",
        ],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Postman (for API testing)",
          "Emulator/Simulator for Android and iOS (included in Android Studio and Xcode)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with programming concepts.",
          "Basic understanding of JavaScript and object-oriented programming.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
          "Google Account": "For accessing Android development tools.",
          "Apple ID": "For accessing Xcode and iOS development tools.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
    description:
      "Designed for aspiring mobile app developers, this course covers the fundamentals of app development for both Android and iOS platforms. With 30 classes, students learn to build mobile apps using languages like Java, Kotlin, Swift, and frameworks like React Native.      ",
    outline: [
      "Introduction to Mobile App Development",
      "Java Fundamentals for Android",
      "Kotlin for Android Development",
      "Android UI Components",
      "Android Advanced Topics",
      "Swift Fundamentals for iOS",
      "iOS UI Components",
      "iOS Advanced Topics",
      "Cross-Platform Development with React Native",
      "Advanced React Native",
    ],
  },
  dataalanysis: {
    name: "dataanalysis",
    title: "Data Analyst/Scientist",
    duration: {
      classes: 20,
      weeks: 7,
    },
    price: {
      online: "KES 37,500",
      physical: "KES 43,000",
    },
    description:
      "In this course, students learn the principles and techniques of data analysis and interpretation. Over 20 classes, participants acquire skills in data manipulation, visualization, and statistical analysis using tools such as Python, R, and SQL.",
    outline: [
      "Introduction to Data Analysis",
      "Data Collection and Cleaning",
      "Data Manipulation with Pandas",
      "Introduction to SQL",
      "Advanced SQL Queries",
      "Data Visualization with Matplotlib",
      "Data Visualization with Seaborn",
      "Introduction to R for Data Analysis",
      "Data Manipulation in R",
      "Statistical Analysis in R",
      "Python for Data Analysis",
      "Exploratory Data Analysis",
      "Introduction to Machine Learning",
      "Supervised Learning Techniques",
      "Unsupervised Learning Techniques",
      "Data Wrangling Techniques",
      "Building Dashboards with Python",
      "Time Series Analysis",
      "Final Project Planning",
      "Final Project Presentation",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "Jupyter Notebook (included with Anaconda distribution)",
          "RStudio (latest version)",
          "SQL Database (MySQL, PostgreSQL, or SQLite)",
          "Visual Studio Code (optional)",
        ],
        "Languages and Libraries": [
          "Python (latest version)",
          "R (latest version)",
          "Pandas",
          "NumPy",
          "Matplotlib",
          "Seaborn",
          "SciPy",
          "Scikit-learn",
        ],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Postman (for API testing)",
          "DBeaver (for database management)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic computer operations.",
          "Basic understanding of programming concepts.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },

  machinelearning: {
    heroImg: hero18,
    name: "machinelearning",
    title: "Machine Learning Engineer",
    duration: {
      classes: 40,
      weeks: 14,
    },
    price: {
      online: "KES 70,000",
      physical: "KES 76,000",
    },
    description:
      "This course delves into the field of machine learning, where students learn to develop algorithms that enable computers to learn from data. Spanning 40 classes, participants explore supervised and unsupervised learning techniques, as well as neural networks and deep learning.",
    outline: [
      "Introduction to Machine Learning",
      "Data Preprocessing and Cleaning",
      "Exploratory Data Analysis",
      "Supervised Learning - Regression",
      "Supervised Learning - Classification",
      "Unsupervised Learning - Clustering",
      "Dimensionality Reduction Techniques",
      "Model Evaluation and Validation",
      "Ensemble Methods",
      "Feature Engineering",
      "Introduction to Neural Networks",
      "Building Neural Networks with TensorFlow",
      "Convolutional Neural Networks (CNNs)",
      "Recurrent Neural Networks (RNNs)",
      "Natural Language Processing (NLP)",
      "Time Series Analysis",
      "Model Deployment",
      "Introduction to Deep Learning",
      "Advanced Deep Learning Techniques",
      "Transfer Learning",
      "Reinforcement Learning Basics",
      "Advanced Reinforcement Learning",
      "Generative Adversarial Networks (GANs)",
      "Machine Learning with Scikit-learn",
      "Deep Learning with Keras and TensorFlow",
      "Hyperparameter Tuning",
      "Working with Big Data",
      "Data Visualization for Machine Learning",
      "Ethics in AI and Machine Learning",
      "Capstone Project - Phase 1",
      "Capstone Project - Phase 2",
      "Capstone Project - Phase 3",
      "Final Project Presentation",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "16GB or higher",
          Storage: "512GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "Jupyter Notebook (included with Anaconda distribution)",
          "PyCharm or Visual Studio Code",
          "TensorFlow (latest version)",
          "Keras (latest version)",
        ],
        "Languages and Libraries": [
          "Python (latest version)",
          "NumPy",
          "Pandas",
          "Matplotlib",
          "Scikit-learn",
          "TensorFlow",
          "Keras",
          "NLTK (for Natural Language Processing)",
          "OpenCV (for Computer Vision)",
        ],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Postman (for API testing)",
          "DBeaver (for database management)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic computer operations.",
          "Basic understanding of programming concepts.",
          "Basic knowledge of Python programming.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },
  cloudarchitecture: {
    heroImg: hero7,
    name: "cloudarchitecture",
    title: "Cloud Architecture",
    duration: {
      classes: 15,
      weeks: 5,
    },
    price: {
      online: "KES 30,000",
      physical: "KES 36,000",
    },
    description:
      "Focused on cloud computing, this course introduces students to cloud platforms and services such as AWS, Azure, and Google Cloud. In 15 classes, participants learn to design, deploy, and manage scalable cloud infrastructure solutions.",
    outline: [
      "Introduction to Cloud Computing",
      "Overview of Cloud Service Models (IaaS, PaaS, SaaS)",
      "Cloud Deployment Models (Public, Private, Hybrid)",
      "Getting Started with AWS",
      "Getting Started with Microsoft Azure",
      "Getting Started with Google Cloud Platform",
      "Cloud Infrastructure Design Principles",
      "Cloud Security Best Practices",
      "Identity and Access Management (IAM)",
      "Networking in the Cloud",
      "Storage Solutions in the Cloud",
      "Database Services in the Cloud",
      "Scaling Applications in the Cloud",
      "Monitoring and Logging",
      "Capstone Project",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "AWS Management Console",
          "Microsoft Azure Portal",
          "Google Cloud Console",
          "Visual Studio Code (or any preferred code editor)",
        ],
        "Languages and Libraries": [
          "Basic knowledge of scripting languages (e.g., Python, Bash)",
          "Cloud SDKs (AWS CLI, Azure CLI, Google Cloud SDK)",
        ],
        "Version Control": "Git (latest version)",
        "Additional Tools": ["Postman (for API testing)"],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic computer operations.",
          "Basic understanding of networking concepts.",
          "Basic knowledge of cloud computing principles.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
          "AWS Account": "For accessing AWS services.",
          "Microsoft Azure Account": "For accessing Azure services.",
          "Google Cloud Account": "For accessing Google Cloud services.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },
  cybersecurityanalysis: {
    heroImg: hero10,
    name: "cybersecurityanalysis",
    title: "Cybersecurity Analysis",
    duration: {
      classes: 20,
      weeks: 7,
    },
    price: {
      online: "KES 60,000",
      physical: "KES 66,000",
    },
    description:
      "This course equips students with the skills and knowledge needed to protect digital systems and data from cyber threats, covering topics such as network security, cryptography, and threat detection. Students learn to analyze security risks and develop strategies to safeguard information assets.",
    outline: [
      "Introduction to Cybersecurity",
      "Fundamentals of Network Security",
      "Understanding Cyber Threats and Attacks",
      "Cryptography Basics",
      "Public Key Infrastructure (PKI)",
      "Network Security Protocols",
      "Security Information and Event Management (SIEM)",
      "Vulnerability Assessment and Management",
      "Penetration Testing Techniques",
      "Threat Detection and Response",
      "Incident Response Planning",
      "Ethical Hacking Principles",
      "Security Policies and Governance",
      "Digital Forensics",
      "Risk Management in Cybersecurity",
      "Secure Software Development",
      "Cloud Security Fundamentals",
      "Mobile Device Security",
      "IoT Security",
      "Capstone Project",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "VirtualBox or VMware (for creating virtual labs)",
          "Kali Linux (latest version)",
          "Wireshark (latest version)",
          "Visual Studio Code (or any preferred code editor)",
        ],
        "Languages and Libraries": ["Python", "Bash scripting"],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Nmap (for network scanning)",
          "Burp Suite (for web vulnerability scanning)",
          "Metasploit Framework",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic computer operations.",
          "Basic understanding of networking concepts.",
          "Basic knowledge of operating systems.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },
  uiuxdesigning: {
    heroImg: hero24,
    name: "uiuxdesigner",
    title: "UI/UX Designer",
    duration: {
      classes: 20,
      weeks: 7,
    },
    price: {
      online: "KES 37,500",
      physical: "KES 43,000",
    },
    description:
      "Designed for aspiring designers, this course focuses on user interface (UI) and user experience (UX) design principles, teaching students how to create visually appealing and user-friendly digital experiences. Topics include wireframing, prototyping, and usability testing, preparing students for careers in interface design.",
    outline: [
      "Introduction to UI/UX Design",
      "Understanding User Needs and Research",
      "Design Thinking Process",
      "Information Architecture",
      "Wireframing Basics",
      "Prototyping Techniques",
      "UI Design Fundamentals",
      "Typography and Color Theory",
      "Creating Style Guides and Design Systems",
      "User Flows and Navigation Design",
      "Mobile and Responsive Design",
      "Usability Testing Methods",
      "Heuristic Evaluation and User Feedback",
      "Iterative Design and Refinement",
      "Tools for UI/UX Design (Figma, Adobe XD, Sketch)",
      "Collaboration with Developers",
      "Accessibility in Design",
      "Portfolio Development",
      "Industry Best Practices",
      "Capstone Project",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Design Tools": [
          "Figma (latest version)",
          "Adobe XD (latest version)",
          "Sketch (latest version, for macOS users)",
        ],
        "Additional Tools": [
          "InVision (for prototyping)",
          "Balsamiq Mockups (for wireframing)",
          "UsabilityHub (for user testing)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic computer operations.",
          "Basic understanding of design principles.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },
  fullstackdeveloper: {
    heroImg: hero2,
    name: "fullstackdeveloper",
    title: "Full Stack Developer",
    duration: {
      classes: 50,
      weeks: 17,
    },
    price: {
      online: "KES 90,000",
      physical: "KES 96,000",
    },
    description:
      "Offering a comprehensive curriculum, this course covers both frontend and backend development technologies, empowering students to build dynamic web applications from start to finish. Students learn programming languages such as HTML, CSS, JavaScript, and frameworks like React and Node.js, gaining expertise in every aspect of web development.",
    outline: [
      "Introduction to Web Development",
      "HTML and CSS Basics",
      "JavaScript Fundamentals",
      "Advanced JavaScript",
      "Version Control with Git and GitHub",
      "Responsive Web Design",
      "Introduction to Frontend Frameworks (React)",
      "State Management in React",
      "Routing in Single Page Applications",
      "Introduction to Backend Development",
      "Node.js and Express.js Basics",
      "Building RESTful APIs",
      "Database Management with MongoDB",
      "Authentication and Authorization",
      "Advanced Backend Development",
      "Deploying Web Applications",
      "Testing and Debugging",
      "Performance Optimization",
      "Security Best Practices",
      "Introduction to DevOps",
      "CI/CD Pipelines",
      "Working with Cloud Platforms (AWS, Azure, GCP)",
      "Building Real-Time Applications",
      "GraphQL Basics",
      "Capstone Project",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "Visual Studio Code (or any preferred code editor)",
          "Latest version of a web browser (Chrome, Firefox, Safari, etc.)",
          "Node.js (latest LTS version)",
          "MongoDB Compass (latest version)",
        ],
        "Libraries and Frameworks": ["React", "Express.js"],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Postman (for API testing)",
          "Live Server extension for Visual Studio Code",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic computer operations.",
          "Basic understanding of web browsers and the internet.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },
  softwareengineering: {
    heroImg: hero20,
    name: "softwareengineering",
    title: "Software Engineering",
    duration: {
      classes: 40,
      weeks: 14,
    },
    price: {
      online: "KES 60,000",
      physical: "KES 66,000",
    },
    description:
      "Tailored for those interested in software development, this course delves into the principles of software engineering, teaching students to design, develop, and maintain software systems efficiently and effectively. Topics include software design patterns, algorithms, and project management methodologies, preparing students for careers in software development.",
    outline: [
      "Introduction to Software Engineering",
      "Software Development Life Cycle",
      "Requirements Engineering",
      "Software Design Patterns",
      "Object-Oriented Design",
      "Algorithms and Data Structures",
      "Version Control with Git and GitHub",
      "Software Testing and Quality Assurance",
      "Agile Methodologies and Scrum",
      "Project Management Fundamentals",
      "Introduction to Databases",
      "SQL and NoSQL Databases",
      "Backend Development with Node.js",
      "Frontend Development with React",
      "RESTful API Design",
      "Microservices Architecture",
      "DevOps Fundamentals",
      "Continuous Integration/Continuous Deployment (CI/CD)",
      "Cloud Computing Basics",
      "Software Security Best Practices",
      "Performance Optimization",
      "Mobile Application Development",
      "Introduction to Machine Learning",
      "Capstone Project",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "Visual Studio Code (or any preferred code editor)",
          "Latest version of a web browser (Chrome, Firefox, Safari, etc.)",
          "Node.js (latest LTS version)",
          "MongoDB Compass (latest version)",
        ],
        "Libraries and Frameworks": ["React", "Express.js"],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Postman (for API testing)",
          "Live Server extension for Visual Studio Code",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic computer operations.",
          "Basic understanding of programming concepts and web development.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },
  foundationprogrammingwebdev: {
    heroImg: hero13,
    name: "foundationprogrammingwebdev",
    title: "Foundation in Programming Concepts and Web Development",
    duration: {
      classes: 20,
      weeks: 7,
    },
    price: {
      online: "KES 40,000",
      physical: "KES 46,000",
    },
    description:
      "This course provides a solid foundation in programming concepts and web development. Over 20 classes, students learn the basics of programming, HTML, CSS, and JavaScript, preparing them for more advanced courses in web development and programming.",
    outline: [
      "Introduction to Programming",
      "Understanding Variables and Data Types",
      "Control Structures: Conditionals and Loops",
      "Functions and Modular Programming",
      "Basic HTML Structure",
      "Styling with CSS",
      "Introduction to JavaScript",
      "Working with the DOM",
      "Event Handling in JavaScript",
      "Responsive Web Design",
      "Introduction to Web Development Tools",
      "Version Control with Git",
      "Building Your First Website",
      "Debugging and Troubleshooting",
      "Web Development Best Practices",
      "Deploying a Website",
      "Introduction to Web Hosting",
      "Web Security Basics",
      "Final Project: Create a Web Page",
      "Review and Next Steps in Web Development",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i3 or higher",
          RAM: "4GB or higher",
          Storage: "128GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.12 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "Visual Studio Code (or any preferred code editor)",
          "Latest version of a web browser (Chrome, Firefox, Safari, etc.)",
        ],
        "Languages and Libraries": ["HTML", "CSS", "JavaScript"],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Postman (for API testing)",
          "Live Server extension for Visual Studio Code",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": ["Familiarity with basic computer operations."],
        Accounts: {
          GitHub: "For version control and project hosting.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },

  deepfrontenddev: {
    heroImg: hero11,
    name: "deepfrontenddev",
    title: "Deep Dive into Frontend Development",
    duration: {
      classes: 13,
      weeks: 4,
    },
    price: {
      online: "KES 30,000",
      physical: "KES 36,000",
    },
    description:
      "This course offers an in-depth exploration of frontend development. Over 13 classes, students will master advanced HTML, CSS, JavaScript, and modern frontend frameworks to create responsive and visually appealing web applications.",
    outline: [
      "Advanced HTML Techniques",
      "CSS Flexbox and Grid Layout",
      "Responsive Web Design Principles",
      "JavaScript ES6+ Features",
      "Asynchronous JavaScript: Promises, Async/Await",
      "Introduction to Frontend Frameworks (React, Vue, Angular)",
      "State Management in Frontend Frameworks",
      "Building Single Page Applications (SPAs)",
      "Advanced CSS Animations and Transitions",
      "Web Accessibility Best Practices",
      "Performance Optimization Techniques",
      "Testing and Debugging Frontend Code",
      "Final Project: Build a Responsive Web Application",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "Visual Studio Code (or any preferred code editor)",
          "Latest version of a web browser (Chrome, Firefox, Safari, etc.)",
        ],
        "Languages and Libraries": [
          "HTML",
          "CSS",
          "JavaScript",
          "React (or another chosen frontend framework)",
        ],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Postman (for API testing)",
          "Live Server extension for Visual Studio Code",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Basic understanding of HTML, CSS, and JavaScript.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },

  backenddevdb: {
    heroImg: hero5,
    name: "backenddevdb",
    title: "Exploration of Backend Development and Databases",
    duration: {
      classes: 13,
      weeks: 4,
    },
    price: {
      online: "KES 30,000",
      physical: "KES 36,000",
    },
    description:
      "This course focuses on backend development and database management. Over 13 classes, students learn to build server-side applications using languages like Node.js and Python, and work with relational databases like MySQL and PostgreSQL.",
    outline: [
      "Introduction to Backend Development",
      "Setting Up a Development Environment",
      "Node.js Basics",
      "Python for Backend Development",
      "Building RESTful APIs",
      "Introduction to Databases",
      "SQL Fundamentals with MySQL",
      "Advanced SQL Queries",
      "Database Design and Normalization",
      "PostgreSQL for Advanced Database Management",
      "Integrating Databases with Backend Applications",
      "Security and Authentication",
      "Final Project: Building a Full-Stack Application",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "Visual Studio Code (or any preferred code editor)",
          "Node.js (latest LTS version)",
          "Python (latest version)",
        ],
        "Languages and Libraries": ["Node.js", "Python", "MySQL", "PostgreSQL"],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Postman (for API testing)",
          "DBeaver (Database management tool)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic programming concepts.",
          "Basic understanding of JavaScript and Python.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },

  appdevspecialization: {
    heroImg: hero21,
    name: "appdevspecialization",
    title: "Specialization in App Development",
    duration: {
      classes: 15,
      weeks: 5,
    },
    price: {
      online: "KES 37,500",
      physical: "KES 43,500",
    },
    description:
      "This course covers the fundamentals of app development for both Android and iOS platforms. In 15 classes, students learn to build mobile apps using languages like Java, Kotlin, Swift, and frameworks like React Native.",
    outline: [
      "Introduction to Mobile App Development",
      "Setting Up Development Environments",
      "Java Basics for Android",
      "Kotlin for Android Development",
      "Swift Basics for iOS",
      "Introduction to Xcode",
      "Building UI with Android Studio",
      "Building UI with SwiftUI",
      "Data Storage and Management",
      "Integrating APIs and Services",
      "User Authentication and Security",
      "Testing and Debugging Apps",
      "Introduction to React Native",
      "Cross-Platform Development with React Native",
      "Final Project: Developing a Full-Featured Mobile App",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
        "Additional Devices": {
          Smartphone: "An Android device for testing Android apps.",
          iPhone: "(Optional) for testing iOS apps.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "Android Studio (latest version)",
          "Java Development Kit (JDK) (latest version)",
          "Kotlin Plugin for Android Studio",
          "Xcode (latest version)",
          "Swift (included with Xcode)",
          "Node.js (latest LTS version)",
          "React Native CLI or Expo CLI",
          "Visual Studio Code or any preferred code editor",
        ],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Postman (for API testing)",
          "Emulator/Simulator for Android and iOS (included in Android Studio and Xcode)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with programming concepts.",
          "Basic understanding of JavaScript and object-oriented programming.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
          "Google Account": "For accessing Android development tools.",
          "Apple ID": "For accessing Xcode and iOS development tools.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },

  cloudarchitectureintro: {
    heroImg: hero16,
    name: "cloudarchitectureintro",
    title: "Introduction to Cloud Architecture",
    duration: {
      classes: 10,
      weeks: 4,
    },
    price: {
      online: "KES 22,500",
      physical: "KES 28,500",
    },
    description:
      "Focused on cloud computing, this course introduces students to cloud platforms and services such as AWS, Azure, and Google Cloud. In 10 classes, participants learn to design, deploy, and manage scalable cloud infrastructure solutions.",
    outline: [
      "Introduction to Cloud Computing",
      "Overview of Cloud Service Models (IaaS, PaaS, SaaS)",
      "Getting Started with AWS",
      "Getting Started with Microsoft Azure",
      "Getting Started with Google Cloud Platform",
      "Cloud Storage and Database Solutions",
      "Cloud Security Best Practices",
      "Deploying Applications to the Cloud",
      "Managing Cloud Resources and Costs",
      "Final Project: Designing a Cloud Architecture Solution",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "AWS Management Console",
          "Azure Portal",
          "Google Cloud Console",
          "Visual Studio Code or any preferred code editor",
        ],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Postman (for API testing)",
          "Docker (latest version)",
          "Kubernetes (latest version)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic computer operations.",
          "Basic understanding of networking and web services.",
        ],
        Accounts: {
          "AWS Account": "For accessing AWS services.",
          "Azure Account": "For accessing Microsoft Azure services.",
          "Google Account": "For accessing Google Cloud services.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },

  dataanalysisml: {
    heroImg: hero18,
    name: "dataanalysisml",
    title: "Data Analysis and Machine Learning",
    duration: {
      classes: 13,
      weeks: 5,
    },
    price: {
      online: "KES 30,000",
      physical: "KES 36,000",
    },
    description:
      "This course provides a comprehensive introduction to data analysis and machine learning. Over 13 classes, students learn the fundamentals of data manipulation, visualization, and machine learning algorithms using tools such as Python, R, and scikit-learn.",
    outline: [
      "Introduction to Data Analysis",
      "Data Wrangling with Pandas",
      "Data Visualization with Matplotlib and Seaborn",
      "Statistical Analysis with Python",
      "Introduction to Machine Learning",
      "Supervised Learning Algorithms",
      "Unsupervised Learning Algorithms",
      "Model Evaluation and Validation",
      "Feature Engineering",
      "Introduction to Neural Networks",
      "Deep Learning Basics",
      "Project: Data Analysis and Machine Learning",
      "Final Presentation and Review",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "Anaconda (latest version) for managing Python packages and environments",
          "Jupyter Notebook",
          "Visual Studio Code or any preferred code editor",
        ],
        "Languages and Libraries": [
          "Python (latest version)",
          "R (latest version)",
          "scikit-learn",
          "Pandas",
          "NumPy",
          "Matplotlib",
          "Seaborn",
          "TensorFlow or PyTorch (for deep learning)",
        ],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Postman (for API testing)",
          "Docker (latest version)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with programming concepts.",
          "Basic understanding of Python programming.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },

  cybersecurity: {
    name: "cybersecurity",
    title: "Cybersecurity Fundamentals",
    duration: {
      classes: 7,
      weeks: 3,
    },
    price: {
      online: "KES 17,500",
      physical: "KES 23,500",
    },
    description:
      "This course provides a foundational understanding of cybersecurity principles and practices. Over 7 classes, students learn about network security, cryptography, threat detection, and best practices for securing digital assets.",
    outline: [
      "Introduction to Cybersecurity",
      "Essential Security Principles",
      "Network Security Fundamentals",
      "Cryptography Basics",
      "Common Cyber Threats",
      "Security Best Practices",
      "Cybersecurity Tools and Techniques",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "Visual Studio Code (or any preferred code editor)",
          "Wireshark (latest version) for network analysis",
          "Nmap (latest version) for network scanning",
        ],
        "Languages and Libraries": [],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "VirtualBox or VMware (for creating virtual machines)",
          "Metasploit (for ethical hacking practice)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic computer operations.",
          "Understanding of networking concepts.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
        },
        Workspace: [
          "A quiet and secure workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },

  prototyping: {
    heroImg: hero19,
    name: "prototyping",
    title: "Prototyping and Design Principles",
    duration: {
      classes: 9,
      weeks: 3,
    },
    price: {
      online: "KES 22,500",
      physical: "KES 28,500",
    },
    description:
      "This course covers fundamental aspects of prototyping and design, focusing on practical skills and techniques. Over 9 classes, participants learn about design thinking, ideation, wireframing, mockups, user testing, and feedback.",
    outline: [
      "Introduction to Prototyping",
      "Design Thinking and Ideation",
      "Creating Wireframes",
      "Designing Mockups",
      "User Testing and Feedback",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "Figma (latest version) for prototyping and design",
        ],
        "Languages and Libraries": [],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Sketch (optional, for additional design capabilities)",
          "Adobe XD (optional, for alternative prototyping)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic computer operations.",
          "Basic understanding of design principles.",
        ],
        Accounts: {
          "Figma Account": "For accessing Figma and saving designs.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },

  stemconcepts: {
    heroImg: hero23,
    name: "stemconcepts",
    title: "STEM Concepts for Kids",
    duration: {
      classes: 20,
      weeks: 7,
    },
    price: {
      online: "KES 32,000",
      physical: "KES 38,000",
    },

    description:
      "This course offers hands-on activities and experiments to teach children about various STEM concepts, fostering curiosity and critical thinking skills. Designed to introduce kids to science, technology, engineering, and mathematics (STEM) in a fun and engaging way.",
    outline: [
      "Introduction to STEM Concepts",
      "Simple Machines and Mechanical Engineering",
      "Renewable Energy and Sustainability",
      "Robotics and Automation",
      "Electronics",
      "3D Design and Printing",
      "Mathematical Concepts in Everyday Life",
    ],
    requirements: {
      "Hardware Requirements": {
        "Arduino Kit": "For hands-on engineering activities.",
      },
      "Software Requirements": {
        Tinkercad: "For virtual modeling and simulations.",
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Curiosity and eagerness to learn.",
          "Interest in science and technology.",
        ],
        Workspace:
          "A safe and organized environment for conducting experiments and activities.",
      },
    },
  },
  animation: {
    heroImg: hero3,
    name: "animation",
    title: "Animation",
    duration: {
      weeks: 6,
      classes: 18,
    },
    price: {
      online: "KES 45,000",
      physical: "KES 51,000",
    },
    description:
      "Learn how to create both 2D and 3D animations in this comprehensive course. Over 6 weeks, participants will explore the principles of animation, storytelling, and digital tools to bring characters and stories to life.",
    outline: [],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
        "Additional Devices": {
          Optional: [
            "Graphics tablet for digital drawing (recommended for 2D animation)",
            "3D modeling software and hardware (optional for exploring 3D animation)",
          ],
        },
      },
      "Software Requirements": {
        "Animation Software": [
          "Adobe Animate (for 2D animation)",
          "Autodesk Maya (for 3D animation)",
        ],
        "Additional Tools": [
          "Storyboarding software",
          "Video editing software (optional, for finalizing animations)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic computer operations.",
          "Creative thinking and storytelling skills.",
        ],
        Workspace: [
          "A quiet and creative workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted animation work.",
        ],
      },
    },
  },

  gaming: {
    heroImg: hero15,
    name: "gaming",
    title: "Game Development",
    duration: {
      weeks: 8,
      classes: 25,
    },
    price: {
      online: "KES 60,000",
      physical: "KES 66,000",
    },
    description:
      "Start building your own games with this hands-on course! Over 8 weeks, children will learn the fundamentals of game design, programming, and problem-solving to create simple yet engaging video games.",
    outline: [],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
        "Additional Devices": {
          Required: [
            "Graphics card (dedicated GPU recommended for better performance)",
          ],
          Optional: [
            "Game controller (for testing games)",
            "External storage (for game assets and backups)",
          ],
        },
      },
      "Software Requirements": {
        "Game Development Software": ["Unity (latest version)"],
        "Programming Languages": ["C#"],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Game development IDE (Integrated Development Environment)",
          "Graphics editing software (optional, for creating game assets)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Basic computer literacy and familiarity with operating systems.",
          "Interest in gaming and game development.",
        ],
        Workspace: [
          "A quiet and creative workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted game development.",
        ],
      },
    },
  },
  python: {
    name: "python",
    title: "Python Programming",

    duration: {
      classes: 20,
      weeks: 7,
    },
    price: {
      online: "KES 30,000",
      physical: "KES 36,000",
    },
    description:
      "This course covers Python programming from basics to advanced concepts. Over 20 classes, participants learn about Python syntax, data structures, object-oriented programming, and more.",
    outline: [
      "Introduction to Python",
      "Python Data Types and Variables",
      "Control Structures: Conditional Statements and Loops",
      "Functions and Modules",
      "File Handling in Python",
      "Object-Oriented Programming with Python",
      "Error Handling and Exceptions",
      "Python Libraries and Packages",
      "Working with APIs (Application Programming Interfaces)",
      "Introduction to Data Science with Python",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environment": [
          "Python (latest version)",
          "Integrated Development Environment (IDE) such as PyCharm, VS Code, or Jupyter Notebook",
        ],
        "Additional Tools": [
          "Git (latest version) for version control",
          "Anaconda (optional, for managing Python environments and packages)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Basic computer literacy and familiarity with operating systems.",
          "Some programming experience (recommended)",
        ],
        Accounts: {
          "GitHub Account":
            "For version control and collaboration (recommended)",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning.",
        ],
      },
    },
  },
  javascript: {
    name: "javascript",
    title: "JavaScript Programming",

    duration: {
      classes: 20,
      weeks: 7,
    },

    price: {
      online: "KES 30,000",
      physical: "KES 36,000",
    },
    description:
      "This course covers JavaScript programming from fundamentals to advanced concepts. Over 20 classes, participants learn about JavaScript syntax, DOM manipulation, asynchronous programming, and more.",
    outline: [
      "Introduction to JavaScript",
      "JavaScript Variables and Data Types",
      "Control Flow: Conditional Statements and Loops",
      "Functions and Scope",
      "Arrays and Objects in JavaScript",
      "DOM Manipulation and Events",
      "Asynchronous JavaScript: Promises and Async/Await",
      "ES6+ Features: Arrow Functions, Template Literals, Destructuring, etc.",
      "Working with JSON Data",
      "Introduction to AJAX and Fetch API",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environment": [
          "Any modern web browser (Chrome, Firefox, Safari, etc.)",
          "Integrated Development Environment (IDE) such as Visual Studio Code, Sublime Text, or Atom",
        ],
        "Additional Tools": [
          "Git (latest version) for version control",
          "Node.js (latest LTS version) for running JavaScript on the server-side (optional)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Basic understanding of HTML and CSS.",
          "Familiarity with programming concepts.",
        ],
        Accounts: {
          "GitHub Account":
            "For version control and collaboration (recommended)",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning.",
        ],
      },
    },
  },
  csharp: {
    heroImg: hero9,
    name: "csharp",
    title: "C# Programming",
    duration: {
      classes: 20,
      weeks: 7,
    },
    price: {
      online: "KES 30,000",
      physical: "KES 36,000",
    },
    description:
      "This course covers C# programming fundamentals and advanced topics, focusing on object-oriented programming principles, .NET framework, and application development.",
    outline: [
      "Introduction to C# Programming",
      "Variables and Data Types in C#",
      "Control Flow: Conditional Statements and Loops",
      "Methods and Functions in C#",
      "Object-Oriented Programming (OOP) Concepts in C#",
      "Arrays and Collections in C#",
      "Exception Handling and Error Management",
      "File Handling in C#",
      "Introduction to LINQ (Language Integrated Query)",
      "Asynchronous Programming with async/await",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environment": [
          "Visual Studio (latest version) or Visual Studio Code",
          ".NET Framework SDK (latest version)",
          "Git (latest version) for version control",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Basic understanding of programming concepts.",
          "Familiarity with basic computer operations.",
        ],
        Accounts: {
          "GitHub Account":
            "For version control and collaboration (recommended)",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning.",
        ],
      },
    },
  },
  codedj: {
    heroImg: hero12,
    name: "codedj",
    title: "Code DJ (Create And Mix Beats With Codes)",
    duration: {
      classes: 12,
      weeks: 4,
    },
    price: {
      online: "KES 38,000",
      physical: "KES 42,000",
    },
    description:
      "In this course, students learn to create music and sound effects using code. By combining programming with audio synthesis techniques, participants can create their own beats, melodies, and soundscapes.",
    outline: [
      "Introduction to Music Programming",
      "Basic Sound Synthesis",
      "Creating Beats with Code",
      "Melody and Harmony Coding",
      "Advanced Sound Design Techniques",
      "Mixing and Mastering",
      "Live Coding Performances",
      "Project: Creating a Complete Track",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
          "Headphones or Speakers": "For audio output.",
        },
      },
      "Software Requirements": {
        "Development Environment": [
          "Sonic Pi (latest version) or any preferred music coding environment",
        ],
        "Additional Tools": [
          "Digital Audio Workstation (optional, for advanced mixing)",
          "MIDI Keyboard (optional, for input)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic programming concepts.",
          "Interest in music production and sound design.",
        ],
        Accounts: {},
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning.",
        ],
      },
    },
  },
  threed: {
    heroImg: hero22,
    name: "threed",
    title: "3D Website Development",
    duration: {
      classes: 15,
      weeks: 5,
    },
    price: {
      online: "KES 50,000",
      physical: "KES 55,000",
    },
    description:
      "In this course, students learn to design and develop interactive 3D websites. Over 15 classes, participants will gain expertise in WebGL, Three.js, and other 3D web technologies to create engaging and visually stunning web applications.",
    outline: [
      "Introduction to 3D Web Development",
      "Basics of WebGL",
      "Getting Started with Three.js",
      "Creating 3D Objects",
      "Textures and Materials",
      "Lighting and Shadows",
      "Camera Controls",
      "Animating 3D Objects",
      "Advanced Three.js Techniques",
      "Integrating 3D Content into Websites",
      "Optimizing 3D Performance",
      "Responsive 3D Design",
      "User Interaction with 3D Elements",
      "Project: Building a 3D Website",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
          "Graphics Card": "Dedicated GPU with WebGL support",
        },
      },
      "Software Requirements": {
        "Development Environments": [
          "Visual Studio Code (or any preferred code editor)",
          "Latest version of a web browser with WebGL support (Chrome, Firefox, Safari, etc.)",
        ],
        "Libraries and Frameworks": [
          "Three.js (latest version)",
          "Node.js (latest LTS version)",
        ],
        "Version Control": "Git (latest version)",
        "Additional Tools": [
          "Blender (optional, for creating 3D models)",
          "Postman (for API testing)",
          "Live Server extension for Visual Studio Code",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic web development concepts.",
          "Basic understanding of JavaScript and 3D graphics principles.",
        ],
        Accounts: {
          GitHub: "For version control and project hosting.",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning sessions.",
        ],
      },
    },
  },

  java: {
    heroImg: hero17,
    name: "java",
    title: "Java Programming",
    duration: {
      classes: 20,
      weeks: 7,
    },
    price: {
      online: "KES 30,000",
      physical: "KES 36,000",
    },
    description:
      "This course covers Java programming fundamentals, object-oriented programming concepts, and GUI development using JavaFX.",
    outline: [
      "Introduction to Java Programming",
      "Variables, Data Types, and Operators",
      "Control Flow: Conditional Statements and Loops",
      "Methods and Functions in Java",
      "Object-Oriented Programming (OOP) Concepts in Java",
      "Arrays and Collections in Java",
      "Exception Handling in Java",
      "File Handling in Java",
      "Introduction to JavaFX for GUI Development",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environment": [
          "Java Development Kit (JDK) (latest version)",
          "Integrated Development Environment (IDE) like IntelliJ IDEA or Eclipse",
          "Git (latest version) for version control",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic programming concepts.",
          "Basic understanding of object-oriented programming.",
        ],
        Accounts: {
          "GitHub Account":
            "For version control and collaboration (recommended)",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning.",
        ],
      },
    },
  },
  cprogramming: {
    heroImg: hero6,
    name: "cprogramming",
    title: "C Programming",
    duration: {
      classes: 20,
      weeks: 7,
    },
    price: {
      online: "KES 30,000",
      physical: "KES 36,000",
    },
    description:
      "This course covers the fundamentals of C programming language, including variables, data types, control flow, functions, arrays, pointers, and file handling.",
    outline: [
      "Introduction to C Programming",
      "Variables, Data Types, and Operators",
      "Control Flow: Conditional Statements and Loops",
      "Functions and Recursion",
      "Arrays and Strings in C",
      "Pointers and Memory Management",
      "File Handling in C",
      "Structures and Unions in C",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environment": [
          "GCC compiler (for Windows or Linux)",
          "Xcode (for macOS)",
          "Integrated Development Environment (IDE) like Code::Blocks, Dev-C++, or Eclipse",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic programming concepts.",
          "Understanding of variables, loops, and basic algorithms.",
        ],
        Accounts: {
          "GitHub Account":
            "For version control and collaboration (recommended)",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning.",
        ],
      },
    },
  },
  rprogramming: {
    name: "rprogramming",
    title: "R Programming",
    duration: {
      classes: 20,
      weeks: 7,
    },
    price: {
      online: "KES 30,000",
      physical: "KES 36,000",
    },
    description:
      "This course covers the fundamentals of R programming language, focusing on statistical computing and data analysis. Participants learn about data types, data visualization, statistical modeling, and data manipulation using R.",
    outline: [
      "Introduction to R Programming",
      "Data Types and Objects in R",
      "Control Structures: Loops and Conditional Statements",
      "Functions and Packages in R",
      "Data Manipulation with dplyr",
      "Data Visualization with ggplot2",
      "Statistical Analysis with R",
      "Advanced Topics in R Programming",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environment": [
          "RStudio (latest version) or any preferred R IDE",
        ],
        "Additional Tools": [
          "R packages: dplyr, ggplot2, tidyr (latest versions)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic programming concepts.",
          "Understanding of statistical concepts (preferred).",
        ],
        Accounts: {
          "RStudio Cloud Account":
            "For cloud-based R programming and collaboration (recommended)",
        },
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning.",
        ],
      },
    },
  },
  cplusplus: {
    heroImg: hero8,
    name: "cplusplus",
    title: "C++ Programming",
    duration: {
      classes: 20,
      weeks: 7,
    },
    price: {
      online: "KES 30,000",
      physical: "KES 36,000",
    },
    description:
      "This course introduces participants to the C++ programming language, covering basic to advanced concepts. Topics include data types, control structures, functions, classes, inheritance, polymorphism, and templates.",
    outline: [
      "Introduction to C++ Programming",
      "Data Types and Variables",
      "Control Structures: Loops and Conditional Statements",
      "Functions and Procedures",
      "Arrays and Pointers",
      "Object-Oriented Programming Concepts",
      "Classes and Objects in C++",
      "Inheritance and Polymorphism",
      "Templates and Standard Template Library (STL)",
    ],
    requirements: {
      "Hardware Requirements": {
        "Computer Specifications": {
          Processor: "Intel i5 or higher",
          RAM: "8GB or higher",
          Storage: "256GB SSD or higher",
          "Operating System":
            "Windows 10, macOS 10.14 or later, or a modern Linux distribution.",
          "Internet Connection":
            "Stable broadband connection for downloading software, accessing online resources, and attending online classes.",
        },
      },
      "Software Requirements": {
        "Development Environment": [
          "Visual Studio (latest version) or any preferred C++ IDE",
        ],
        "Additional Tools": [
          "Git (latest version) for version control",
          "C++ Compiler (included with IDE)",
        ],
      },
      "Additional Requirements": {
        "Basic Knowledge": [
          "Familiarity with basic programming concepts.",
          "Understanding of object-oriented programming concepts (preferred).",
        ],
        Accounts: {},
        Workspace: [
          "A quiet and comfortable workspace with minimal distractions.",
          "Adequate power supply and backup for uninterrupted learning.",
        ],
      },
    },
  },
};
