import React, { useEffect, useState } from "react";
import LandingSlide from "./slides/LandingSlide";
import "./RegistrationForm.css";
import QuestionsSlide from "./slides/QuestionsSlide";
import ContactForm from "./slides/ContactForm";
import { questions } from "./questionsData";

export default function RegistrationForm() {
  const [questionNo, setQuestionNo] = useState(0);
  const [isLandingslide, setIsLandingSlide] = useState(true);
  const [isContactForm, setIsContactForm] = useState(false);
  const [dataChoices, setDataChoices] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dataChoices]);

  // LOGIC
  // 0a -> 1
  // 0b -> 2
  // 0c -> 3
  // 0D -> 4
  // 0e -> 5
  // 0f -> 6
  // 0g -> 7

  return (
    <div className="registration-form">
      <div className="container">
        {isLandingslide ? (
          <LandingSlide setIsLandingSlide={setIsLandingSlide} />
        ) : isContactForm ? (
          <ContactForm
            setIsContactForm={setIsContactForm}
            setDataChoices={setDataChoices}
            dataChoices={dataChoices}
          />
        ) : (
          <QuestionsSlide
            currentQuestion={questions[questionNo].id}
            question={questions[questionNo].question}
            choices={questions[questionNo].choices}
            setQuestionNo={setQuestionNo}
            setIsContactForm={setIsContactForm}
            setDataChoices={setDataChoices}
            dataChoices={dataChoices}
          />
        )}
      </div>
    </div>
  );
}
