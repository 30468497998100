import "./Programmes.css";
import Programme from "./components/Programme";
import SEO from "../../components/SEO";
import conicalImage from "../../assets/heroImages/ml_hero.png";

export default function Programmes() {
  return (
    <>
      <SEO
        title="Summer Coding Camp"
        description="A premier program designed for kids aged 6 to 17. Our camp offers immersive, hands-on experiences in animation, Python programming, and dance, ensuring a fun and educational summer. Participants will build exciting projects, learn valuable coding skills, and explore their creativity. Secure your child's spot today for a transformative and enriching summer! Limited slots available."
        image={conicalImage}
        url="https://www.coderhythm.electrixitaty.co/programmes"
        keywords={
          "Summer Coding Camp,Kids Coding Classes, Animation for Kids, Python Programming for Kids, Dance Classes for Kids, Hands-on Learning, Educational Summer Camp, Fun Summer Activities, Creative Summer Camp, Code Rhythm Camp, Code Rhythm, coding, creativity, tech education"
        }
      />
      <div className="programmes">
        <Programme />
      </div>
    </>
  );
}
