import "./Payment.css";
import logo from "../../assets/img/code_rhythm_icon.png";
import { useEffect, useState } from "react";
import loadPaystackScript from "../../utilities/loadPaystackScript";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../../firebaseConfig";
import { get, ref, set } from "firebase/database";
import { format, startOfToday } from "date-fns";

export default function Payment() {
  const [parentFirstName, setParentFirstName] = useState("");
  const [parentSecondName, setParentSecondName] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [parentNoCalling, setParentNoCalling] = useState("");
  const [parentNoApp, setParentNoApp] = useState("");
  const [studentsDetail, setStudentsDetails] = useState({});
  const [isOnline, setIsOnline] = useState(false);

  const [numberOfStudents, setNumberOfStudents] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);

  const ANIMATION_PRICE = 12000;
  const DANCING_PRICE = 6000;
  const PYTHON_PRICE = 12000;
  const DISCOUNT = 5000;
  const DEADLINE_DAYS = 0;

  const handleRadioModeChange = (event, index) => {
    const mode = event.target.value;

    setStudentsDetails((prevDetails) => {
      const updatedCourses = prevDetails[index]?.courses?.filter(
        (course) => mode === "online" || course !== "dancing"
      );

      const updatedDetails = {
        ...prevDetails,
        [index]: {
          ...prevDetails[index],
          mode: mode,
          courses: updatedCourses,
        },
      };

      return updatedDetails;
    });

    if (mode === "online") {
      setIsOnline(true);
    } else {
      setIsOnline(false);
    }
  };

  const handleCheckboxCourseChange = (event, index) => {
    const course = event.target.value;
    const isChecked = event.target.checked;

    setStudentsDetails((prevDetails) => {
      const updatedCourses = isChecked
        ? [...(prevDetails[index]?.courses || []), course]
        : prevDetails[index]?.courses.filter((c) => c !== course);

      return {
        ...prevDetails,
        [index]: {
          ...prevDetails[index],
          courses: updatedCourses,
        },
      };
    });
  };

  const getDifferenceInDays = (startDate) => {
    const today = new Date();
    const cohortDate = new Date(startDate);
    const diffTime = cohortDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const cohorts = [
    { label: "1st Cohort (8th July to 19th July)", startDate: "2024-07-08" },
    { label: "2nd Cohort (22nd July to 2nd August)", startDate: "2024-07-22" },
    {
      label: "3rd Cohort (5th August to 16th August)",
      startDate: "2024-08-05",
    },
    {
      label: "4th Cohort (19th August to 30th August)",
      startDate: "2024-08-19",
    },
  ];

  const handleCohortChange = (event, studentIndex, cohortLabel) => {
    const selectedCohort = event.target.value;
    setStudentsDetails((prevDetails) => ({
      ...prevDetails,
      [studentIndex]: {
        ...prevDetails[studentIndex],
        cohorts: [selectedCohort],
      },
    }));
  };

  const isAnyCourseSelected = () => {
    for (let i = 0; i < numberOfStudents; i++) {
      const student = studentsDetail[i];
      if (!(student?.courses && student.courses.length > 0)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const calculateTotalAmount = () => {
      let total = 0;
      let countStudentsWithCourses = 0;
      console.log(isOnline);

      // Iterate through each student in studentsDetail
      for (let i = 0; i < numberOfStudents; i++) {
        const student = studentsDetail[i];
        if (
          !isOnline &&
          student &&
          student.courses &&
          student.courses.length > 0
        ) {
          // Check each course the student is enrolled in
          student.courses.forEach((course) => {
            switch (course) {
              case "animation":
                total += ANIMATION_PRICE;
                break;
              case "dancing":
                total += DANCING_PRICE;
                break;
              case "python":
                total += PYTHON_PRICE;
                break;
              default:
                break;
            }
          });
          countStudentsWithCourses++;

          // Apply discount only if more than one student with courses
          if (countStudentsWithCourses > 1) {
            total -= DISCOUNT;
          }
          // if (countStudentsWithCourses > 1) {
          //   total -= DISCOUNT * countStudentsWithCourses;
          // }
        } else if (
          isOnline &&
          student &&
          student.courses &&
          student.courses.length > 0
        ) {
          // Check each course the student is enrolled in
          student.courses.forEach((course) => {
            switch (course) {
              case "animation":
                total += ANIMATION_PRICE;
                break;

              case "python":
                total += PYTHON_PRICE;
                break;
              default:
                break;
            }
          });
          countStudentsWithCourses++;
        }
      }

      setTotalAmount(total);
    };

    calculateTotalAmount();
  }, [studentsDetail, numberOfStudents, isOnline]);

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [password, setPassword] = useState("");

  const today = startOfToday();
  const todayFormatted = format(today, "EEE, do MMM yyyy");

  const monthFormatted = format(today, "MMM");

  const now = new Date();
  const timeFormatted = format(now, "hh:mm a");

  const handleSubmit = async () => {
    // Check if at least one course is selected for each student
    const allStudentsValid = Object.values(studentsDetail).every(
      (student) => student?.courses && student.courses.length > 0
    );

    if (!allStudentsValid) {
      alert("Please select at least one course for each student.");
      return;
    }

    setIsSubmiting(true);

    const data = {
      parentDetails: {
        email: parentEmail,
        firstName: parentFirstName,
        secondName: parentSecondName,
        app: parentNoApp,
        call: parentNoCalling,
      },
      studentsDetail: studentsDetail,
      date: todayFormatted,
      time: timeFormatted,
    };

    try {
      // Attempt to sign in the user first
      signInWithEmailAndPassword(auth, parentEmail, password)
        .then(async (userCredential) => {
          // Signed in successfully
          const user = userCredential.user;
          if (user) {
            const userRef = ref(
              db,
              `programme/summer/${monthFormatted}/` + user.uid
            );

            // Fetch current data once
            const snapshot = await get(userRef);
            const userData = snapshot.val();

            // Merge new data with existing data
            const updatedData = {
              ...userData,
              moreApplication: [...(userData?.moreApplication || []), data],
            };
            // Update database with merged data
            await set(userRef, updatedData);

            alert("You successfully paid for the Summer Coding Camp!");
            window.location.href = "/";
          }
        })
        .catch((signInError) => {
          // User does not exist, attempt to create a new account

          if (
            signInError.code === "auth/user-not-found" ||
            signInError.code === "auth/invalid-credential"
          ) {
            createUserWithEmailAndPassword(auth, parentEmail, password)
              .then(async (userCredential) => {
                // Signed up successfully
                const user = userCredential.user;
                if (user) {
                  const userRef = ref(
                    db,
                    `programme/summer/${monthFormatted}/` + user.uid
                  );
                  await set(userRef, data);
                  alert("You successfully paid for the Summer Coding Camp! ");
                  window.location.href = "/";
                }
              })
              .catch((createError) => {
                const createErrorCode = createError.code;
                const createErrorMessage = createError.message;
                console.error(
                  "Firebase Auth Create Error:",
                  createErrorCode,
                  createErrorMessage
                );
                alert(createErrorMessage); // Display error message to the user
              });
          } else {
            const signInErrorCode = signInError.code;
            const signInErrorMessage = signInError.message;
            console.error(
              "Firebase Auth Sign In Error:",
              signInErrorCode,
              signInErrorMessage
            );
            alert(signInErrorMessage); // Display error message to the user
          }
        })
        .finally(() => setIsSubmiting(false));
    } catch (error) {
      alert("Oops, a problem occurred");
      setIsSubmiting(false);
      console.error("Error:", error);
    }
  };

  const [paystackLoaded, setPaystackLoaded] = useState(false);

  useEffect(() => {
    loadPaystackScript("https://js.paystack.co/v1/inline.js")
      .then(() => setPaystackLoaded(true))
      .catch((error) => console.error(error));
  }, []);

  const payWithPaystack = (e) => {
    e.preventDefault();

    if (!paystackLoaded) {
      alert("Paystack script not loaded yet");
      return;
    }

    let handler = window.PaystackPop.setup({
      key: "pk_live_c8e566ed3a5312e35c36d83e104bd6ba018df59e",
      email: parentEmail,
      amount: `${totalAmount}` * 100,
      currency: "KES",
      customer_email: "receipts@coderhythm.electrixitaty.co",
      channels: ["mobile_money", "card"],
      ref: "" + Math.floor(Math.random() * 1000000000 + 1), // Generates a pseudo-unique reference.
      callback: function (response) {
        let message = "Payment complete! Reference: " + response.reference;
        alert(message);
        console.log(response);
        handleSubmit();
        // Open Calendly link in a new tab after successful payment
        window.open("https://coderhythm.electrixitaty.co/programmes", "_blank");

        // Redirect to Calendly link in the same tab after successful payment
        // window.location.href = "https://calendly.com/vibesandvent/30min";
      },
      onClose: function () {
        alert("Transaction was not completed, window closed.");
      },
    });

    handler.openIframe(); // Use iframe instead of popup
  };

  return (
    <div className="payment">
      <div className="payment-container">
        <img alt="code rhythm" src={logo} />
        <h1>Summer Coding Camp</h1>
        <h3>Panenka Education and</h3>
        <h3>
          Code Rhythm <span>powered by Electrixitaty</span>
        </h3>
        <p className="description">
          A two-week immersive program for kids aged 5 to 17, running Monday to
          Friday. It aims to inspire creativity and technical skills through a
          blend of technology and creative arts. The camp offers both physical
          and online sessions to accommodate a wide range of participants.{" "}
          <a
            className="learnmore-btn"
            href="../SUMMER_CODING_CAMP_BROCHURE.pdf"
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Learn More
          </a>
        </p>

        <form id="paymentForm" onSubmit={payWithPaystack}>
          <h3>Parent/Guardian's Info</h3>
          <div className="form-group">
            <div className="input-div">
              <label>First Name</label>
              <input
                value={parentFirstName}
                type={"text"}
                onChange={(e) => setParentFirstName(e.target.value)}
                placeholder="Parent's First Name*"
                required
              />
            </div>
            <div className="input-div">
              <label>Second Name</label>
              <input
                type={"text"}
                value={parentSecondName}
                onChange={(e) => setParentSecondName(e.target.value)}
                placeholder="Parent's Second Name*"
                required
              />
            </div>
          </div>
          <div className="input-div">
            <label>Email Address</label>
            <input
              value={parentEmail}
              onChange={(e) => setParentEmail(e.target.value)}
              placeholder="Parent's email Address*"
              type={"email"}
              required
            />
          </div>
          <div className="input-div">
            <label>Phone Number (calling)</label>
            <input
              value={parentNoCalling}
              onChange={(e) => setParentNoCalling(e.target.value)}
              placeholder="Parent's Calling phone number*"
              type={"tel"}
              required
            />
          </div>
          <div className="input-div">
            <label>Phone Number (whatsapp)</label>
            <input
              value={parentNoApp}
              onChange={(e) => setParentNoApp(e.target.value)}
              placeholder="Parent's Whatsapp phone number*"
              type={"tel"}
              required
            />
          </div>
          <br />
          <h4>Pricing and Discounts</h4>
          <ul>
            <li>
              The enrollment fee is determined by the selected courses:
              <br />
              Animation (<strong>KES 12,000</strong>)
              <br /> Python Programming (<strong>KES 12,000</strong>).
              <br />
              Dancing (<strong>KES 6,000</strong>).
            </li>
            <li>
              A discount of <strong>KES 5,000</strong> is applied to the total
              fee if more than one student is enrolled.
            </li>
          </ul>
          <br />
          <h3>Student(s)'s Info</h3>
          <div className="form-group no-of-kids">
            <label>Number of Kids enrolling</label>
            <input
              value={numberOfStudents}
              type="number"
              onChange={(e) => setNumberOfStudents(e.target.value)}
            />
          </div>
          {Array.from({ length: numberOfStudents }, (_, index) => (
            <div key={index}>
              <div className="student-detail">
                <p>Student {index + 1}</p>

                <div className="form-group">
                  <div className="input-div">
                    <label>First Name</label>
                    <input
                      value={studentsDetail[index]?.firstName || ""}
                      type={"text"}
                      onChange={(e) =>
                        setStudentsDetails({
                          ...studentsDetail,
                          [index]: {
                            ...studentsDetail[index],
                            firstName: e.target.value,
                          },
                        })
                      }
                      placeholder="Student's First Name*"
                      required
                    />
                  </div>
                  <div className="input-div">
                    <label>Second Name</label>
                    <input
                      value={studentsDetail[index]?.secondName || ""}
                      type={"text"}
                      onChange={(e) =>
                        setStudentsDetails({
                          ...studentsDetail,
                          [index]: {
                            ...studentsDetail[index],
                            secondName: e.target.value,
                          },
                        })
                      }
                      placeholder="Student's Second Name*"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-div">
                    <label>Student's Age</label>
                    <input
                      value={studentsDetail[index]?.age || ""}
                      type={"number"}
                      onChange={(e) =>
                        setStudentsDetails({
                          ...studentsDetail,
                          [index]: {
                            ...studentsDetail[index],
                            age: e.target.value,
                          },
                        })
                      }
                      placeholder="Student's Age*"
                      required
                      className="age"
                    />
                  </div>
                  <div className="input-div">
                    <label>email</label>
                    <input
                      value={studentsDetail[index]?.email || ""}
                      type={"email"}
                      onChange={(e) =>
                        setStudentsDetails({
                          ...studentsDetail,
                          [index]: {
                            ...studentsDetail[index],
                            email: e.target.value,
                          },
                        })
                      }
                      placeholder="Student's email address*"
                      required
                      className="email"
                    />
                  </div>
                </div>
                <div className="input-div">
                  <label>Whatsapp</label>
                  <input
                    value={studentsDetail[index]?.app || ""}
                    type={"tel"}
                    onChange={(e) =>
                      setStudentsDetails({
                        ...studentsDetail,
                        [index]: {
                          ...studentsDetail[index],
                          app: e.target.value,
                        },
                      })
                    }
                    placeholder="Student's Whatsapp Number*"
                    className="p-number"
                  />
                </div>
                <div className="mode">
                  <div>
                    <input
                      type="radio"
                      value="physical"
                      checked={studentsDetail[index]?.mode === "physical"}
                      onChange={(e) => handleRadioModeChange(e, index)}
                    />
                    <label>Physical</label>
                  </div>
                  <div className="online">
                    <input
                      type="radio"
                      value="online"
                      checked={studentsDetail[index]?.mode === "online"}
                      onChange={(e) => handleRadioModeChange(e, index)}
                      required
                    />{" "}
                    <label>Online</label>
                  </div>
                </div>
              </div>

              {studentsDetail[index]?.mode && (
                <>
                  <h4 style={{ marginTop: "2vh" }}> Courses to be taught</h4>
                  <div className="selected-courses">
                    <div className="selected-course animation">
                      <input
                        type="checkbox"
                        value="animation"
                        checked={studentsDetail[index]?.courses?.includes(
                          "animation"
                        )}
                        onChange={(e) => handleCheckboxCourseChange(e, index)}
                      />
                      <label>Animation (KES 12,000)</label>
                    </div>
                    <div className="selected-course python">
                      <input
                        type="checkbox"
                        value="python"
                        checked={studentsDetail[index]?.courses?.includes(
                          "python"
                        )}
                        onChange={(e) => handleCheckboxCourseChange(e, index)}
                      />
                      <label>Python Programming (KES 12,000)</label>
                    </div>
                    {!isOnline && (
                      <div className="selected-course Dancing">
                        <input
                          type="checkbox"
                          value="dancing"
                          checked={studentsDetail[index]?.courses?.includes(
                            "dancing"
                          )}
                          onChange={(e) => handleCheckboxCourseChange(e, index)}
                        />
                        <label>Dancing (KES 6,000)</label>
                      </div>
                    )}
                  </div>
                </>
              )}

              {studentsDetail[index]?.mode && <h4>Cohort to Join</h4>}
              {studentsDetail[index]?.mode &&
                cohorts.map((cohort, cohortIndex) => (
                  <>
                    <div key={cohortIndex} className="cohort">
                      <input
                        type="radio"
                        id={`cohort-${cohortIndex}-${index}`}
                        name={`cohort-${index}`}
                        value={cohort.label}
                        checked={studentsDetail[index]?.cohorts?.includes(
                          cohort.label
                        )}
                        onChange={(e) =>
                          handleCohortChange(e, index, cohort.label)
                        }
                        disabled={
                          getDifferenceInDays(cohort.startDate) <= DEADLINE_DAYS
                        }
                        required
                      />
                      <label
                        htmlFor={`cohort-${cohortIndex}-${index}`}
                        style={
                          getDifferenceInDays(cohort.startDate) <= DEADLINE_DAYS
                            ? { color: "red" }
                            : {}
                        }
                      >
                        {`${cohort.label} ${
                          getDifferenceInDays(cohort.startDate) <= DEADLINE_DAYS
                            ? "(CLOSED)"
                            : ""
                        }`}
                      </label>
                    </div>
                  </>
                ))}

              <hr />
            </div>
          ))}

          <h3 className="total">
            {" "}
            KES {totalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </h3>
          <button
            className="pay-btn"
            type="submit"
            style={
              isSubmiting || !isAnyCourseSelected()
                ? { backgroundColor: "gray" }
                : {}
            }
            disabled={isSubmiting || !isAnyCourseSelected()}
          >
            {isSubmiting ? "Processing..." : "Pay Now"}
          </button>
        </form>
      </div>
    </div>
  );
}
