import React, { useEffect, useState } from "react";
import "./Hero.css";
import heroImg from "../../assets/img/code_rhythm_hero.jpg";

export default function Hero() {
  const [frontBgImage, setFrontBgImage] = useState(null);
  const [backBgImage, setBackBgImage] = useState(null);
  const [cardBgImage, setCardBgImage] = useState(null);

  useEffect(() => {
    const backgroundImagesList = [
      heroImg,
      "https://images.pexels.com/photos/4260325/pexels-photo-4260325.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/8553821/pexels-photo-8553821.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/7283637/pexels-photo-7283637.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4261789/pexels-photo-4261789.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4260325/pexels-photo-4260325.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/9159283/pexels-photo-9159283.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    ];
    let intervalId;

    const getRandomIndexExcluding = (excludeIndex) => {
      let randomIndex;
      do {
        randomIndex = Math.floor(Math.random() * backgroundImagesList.length);
      } while (randomIndex === excludeIndex);
      return randomIndex;
    };

    const flipAndChangeImages = () => {
      const frontRandomIndex = Math.floor(
        Math.random() * backgroundImagesList.length
      );
      const backRandomIndex = getRandomIndexExcluding(frontRandomIndex);
      const cardRandomIndex = getRandomIndexExcluding(frontRandomIndex);

      setFrontBgImage(`url(${backgroundImagesList[frontRandomIndex]})`);
      setBackBgImage(`url(${backgroundImagesList[backRandomIndex]})`);
      setCardBgImage(`url(${backgroundImagesList[cardRandomIndex]})`);
    };

    // Initial flip and image change
    flipAndChangeImages();

    // Set an interval to trigger the flip and image change every 10 seconds
    intervalId = setInterval(() => {
      flipAndChangeImages();
    }, 5000); // 10 seconds in milliseconds

    // Clear the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <section className="hero">
      {/* <div className="hero-container"> */}
      <div className="hero-content">
        <div className="salutation">
          <h2>Code Rhythm,</h2>
          <h3>Where Coding Cultivates Mind and Career! </h3>
        </div>
        <p>
          Embark on a transformative journey with Code Rhythm, where coding
          transcends beyond technical skills to nurture the mind and shape
          careers. Our platform offers more than just coding education; it's a
          holistic experience that empowers individuals to become problem
          solvers, critical thinkers, and entrepreneurs. Join us and discover
          the transformative power of coding at Code Rhythm.
        </p>
        <a
          href="https://forms.gle/yk3QhrrxtYQ8T5f49"
          target={"_blank"}
          rel="noreferrer"
          className="button"
        >
          Enroll Now
        </a>
      </div>
      {/* <div className="hero-image">
        <img src={heroImg} alt="code rhythm intake" />
      </div> */}
      <div className="carousel-card" style={{ backgroundImage: cardBgImage }}>
        <div className="carousel-card-inner">
          <div
            className="carousel-card-front"
            style={{ backgroundImage: frontBgImage }}
          ></div>
          <div
            className="carousel-card-back"
            style={{ backgroundImage: backBgImage }}
          ></div>
        </div>
      </div>
      {/* </div> */}
    </section>
  );
}
