import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";

export default function Header() {
  // const [scrollDirection, setScrollDirection] = useState(null);

  // useEffect(() => {
  //   let lastScrollTop;
  //   window.addEventListener(
  //     "scroll",
  //     function () {
  //       let st = window.pageYOffset || document.documentElement.scrollTop;
  //       if (st > lastScrollTop) {
  //         setScrollDirection("downscroll");
  //       } else if (st < lastScrollTop) {
  //         setScrollDirection("upscroll");
  //       } // else was horizontal scroll
  //       lastScrollTop = st <= 0 ? 0 : st;
  //     },
  //     false
  //   );
  // }, [scrollDirection]);

  const [isCourseHovered, setIsCourseHovered] = useState(false);
  const [isDripDownHovered, setIsDropDownHovered] = useState(null);

  return (
    <header className={`header`}>
      <h1 className="logo">
        CODE <span style={{ color: "#3691CD" }}>RHYTHM</span>
      </h1>
      <nav>
        <ul className="navigations">
          <li className="nav-option">
            <a href="/#">Home</a>
          </li>
          {/* <li className="nav-option">
            <a href="/#about">About</a>
          </li> */}

          <li
            className="nav-option"
            onMouseEnter={() => setIsCourseHovered(true)}
            onMouseLeave={() => setIsCourseHovered(false)}
          >
            <a href="/#courses">Courses Offered</a>
            {isCourseHovered && (
              <div className="drops">
                <ul
                  className="dropdown"
                  onMouseEnter={() => setIsCourseHovered(true)}
                >
                  <li
                    onMouseEnter={() => setIsDropDownHovered(0)}
                    onMouseLeave={() => setIsDropDownHovered(null)}
                  >
                    <Link
                      className="link"
                      onClick={() => setIsCourseHovered(false)}
                      style={{ width: "100%", textDecoration: "none" }}
                      to={"/courses/fullspectrum"}
                    >
                      Full Spectrum
                    </Link>
                  </li>
                  <li
                    onMouseEnter={() => setIsDropDownHovered(1)}
                    onMouseLeave={() => setIsDropDownHovered(null)}
                  >
                    <Link
                      className="link"
                      onClick={() => setIsCourseHovered(false)}
                      style={{ width: "100%", textDecoration: "none" }}
                      to={"/courses/specialise"}
                    >
                      Specialised Courses
                    </Link>
                  </li>
                  <li
                    onMouseEnter={() => setIsDropDownHovered(3)}
                    onMouseLeave={() => setIsDropDownHovered(null)}
                  >
                    <Link
                      className="link"
                      onClick={() => setIsCourseHovered(false)}
                    >
                      Language Mastering
                    </Link>
                  </li>

                  <li
                    onMouseEnter={() => setIsDropDownHovered(4)}
                    onMouseLeave={() => setIsDropDownHovered(null)}
                  >
                    <Link
                      className="link"
                      onClick={() => setIsCourseHovered(false)}
                      style={{ width: "100%", textDecoration: "none" }}
                      to={"/"}
                    >
                      Special Courses
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="link"
                      onClick={() => setIsCourseHovered(false)}
                      style={{ width: "100%", textDecoration: "none" }}
                      to={"/courses/stemconcepts"}
                    >
                      STEM
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="link"
                      onClick={() => setIsCourseHovered(false)}
                      style={{ width: "100%", textDecoration: "none" }}
                      to={"/courses/animation"}
                    >
                      Animation
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="link"
                      onClick={() => setIsCourseHovered(false)}
                      style={{ width: "100%", textDecoration: "none" }}
                      to={"/courses/gaming"}
                    >
                      Game Development
                    </Link>
                  </li>
                </ul>
                {isDripDownHovered === 0 && (
                  <ol
                    className="drop-dropdown"
                    onMouseEnter={() => setIsDropDownHovered(0)}
                    onMouseLeave={() => setIsDropDownHovered(null)}
                  >
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/webdevelopment"}
                      >
                        Wed Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/appdevelopment"}
                      >
                        App Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/dataalanysis"}
                      >
                        Data Analysis
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/machinelearning"}
                      >
                        Machine Learning
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/cloudarchitecture"}
                      >
                        Cloud Architecture
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/cybersecurityanalysis"}
                      >
                        Cybersecurity Analyst
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/uiuxdesigning"}
                      >
                        Ui/Ux Designing
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/fullstackdeveloper"}
                      >
                        Full Stack Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/softwareengineering"}
                      >
                        Software Enginner
                      </Link>
                    </li>
                  </ol>
                )}
                {isDripDownHovered === 1 && (
                  <ul
                    className="drop-dropdown"
                    style={{ marginTop: 40 }}
                    onMouseEnter={() => setIsDropDownHovered(1)}
                    onMouseLeave={() => setIsDropDownHovered(null)}
                  >
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/foundationprogrammingwebdev"}
                      >
                        Foundation in Programming Concepts and Web Development:
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/deepfrontenddev"}
                      >
                        Deep Dive into Frontend Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/backenddevdb"}
                      >
                        Exploration of Backend Development and Databases
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/appdevspecialization"}
                      >
                        Specialization in App Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/cloudarchitectureintro"}
                      >
                        Introduction to Cloud Architecture
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/dataanalysisml"}
                      >
                        Data Analysis and Machine Learning
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/cybersecurity"}
                      >
                        Cybersecurity Fundamentals
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/prototyping"}
                      >
                        Prototyping and Design Principles
                      </Link>
                    </li>
                  </ul>
                )}
                {isDripDownHovered === 3 && (
                  <ul
                    className="drop-dropdown"
                    style={{ marginTop: 80 }}
                    onMouseEnter={() => setIsDropDownHovered(3)}
                    onMouseLeave={() => setIsDropDownHovered(null)}
                  >
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "100%", textDecoration: "none" }}
                        to={"/courses/python"}
                      >
                        Python Programming
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/cprogramming"}
                      >
                        C Programming
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "100%", textDecoration: "none" }}
                        to={"/courses/cplusplus"}
                      >
                        C++ Programming
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "100%", textDecoration: "none" }}
                        to={"/courses/csharp"}
                      >
                        C# Programming
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "100%", textDecoration: "none" }}
                        to={"/courses/java"}
                      >
                        Java Programming
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/javascript"}
                      >
                        Javascript Programming
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/rprogramming"}
                      >
                        R Programming
                      </Link>
                    </li>
                  </ul>
                )}
                {isDripDownHovered === 4 && (
                  <ul
                    className="drop-dropdown"
                    style={{ marginTop: 200 }}
                    onMouseEnter={() => setIsDropDownHovered(4)}
                    onMouseLeave={() => setIsDropDownHovered(null)}
                  >
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/threed"}
                      >
                        3D Web Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="link"
                        onClick={() => setIsCourseHovered(false)}
                        style={{ width: "inherit" }}
                        to={"/courses/codedj"}
                      >
                        Dj Code
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
            )}
          </li>
          <li className="nav-option">
            <Link to="programmes">Programmes</Link>
          </li>
          {/* <li className="nav-option">
            <a
              href="/#why-us"
              style={isCourseHovered ? { color: "white" } : { color: "black" }}
            >
              Why US
            </a>
          </li> */}
        </ul>
      </nav>
      <a
        onClick={() => setIsCourseHovered(false)}
        href="https://forms.gle/yk3QhrrxtYQ8T5f49"
        target={"_blank"}
        className="button"
        rel="noreferrer"
      >
        Enroll Now
      </a>
    </header>
  );
}
