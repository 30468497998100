import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Courses.css";

import { coursesData } from "./coursesData";

export default function Courses() {
  const [courseToShow, setCourseToShow] = useState("");
  const location = useLocation();

  useEffect(() => {
    setCourseToShow(location.pathname.split("/courses/")[1]);
    window.scrollTo(0, 0);
  }, [location]);

  const renderRequirements = (requirements) => {
    return Object.entries(requirements).map(([category, details], index) => (
      <div key={index}>
        <h5>{category}</h5>
        {typeof details === "object" && !Array.isArray(details) ? (
          <ul>
            {Object.entries(details).map(([key, value], index) => (
              <li key={index}>
                {key}: {typeof value === "string" ? value : ""}
                {typeof value === "object" && !Array.isArray(value) ? (
                  <ul>
                    {Object.entries(value).map(([subKey, subValue], index) => (
                      <li key={index}>
                        {subKey}: {typeof subValue === "object" ? "" : subValue}
                      </li>
                    ))}
                  </ul>
                ) : null}
                {Array.isArray(value) ? (
                  <ul>
                    {value.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </ul>
        ) : Array.isArray(details) ? (
          <ul>
            {details.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : null}
      </div>
    ));
  };

  return (
    <div
      className="courses"
      style={{
        backgroundImage: `url(${coursesData[courseToShow]?.heroImg})`,
      }}
    >
      <div className="courses-hero">
        <div className="courses-hero-left">
          <div className="course-info">
            <h1>{coursesData[courseToShow]?.title}</h1>
            <p>{coursesData[courseToShow]?.description}</p>
          </div>
          {coursesData[courseToShow]?.duration && (
            <div className="details">
              <div className="">
                <h2>{coursesData[courseToShow]?.duration.classes}</h2>
                <p>classess</p>
              </div>
              <div className="">
                <h2>{coursesData[courseToShow]?.duration.weeks}</h2>
                <p>Weeks</p>
              </div>
              <div className="">
                <h2>KES {coursesData[courseToShow]?.price.physical}</h2>
                <p>for physical</p>
              </div>
              <div className="">
                <h2>KES {coursesData[courseToShow]?.price.online}</h2>
                <p>for online</p>
              </div>
            </div>
          )}
          {coursesData[courseToShow]?.requirements ? (
            <div className="outline">
              <h3>Admission Requirements</h3>
              {/* render all rquriements here dynamically */}
              {renderRequirements(
                coursesData[courseToShow]?.requirements || {}
              )}
            </div>
          ) : (
            <div className="enroll-div">
              {coursesData[courseToShow]?.requirements ? (
                <h3>Course Outline</h3>
              ) : (
                <h3>Courses:</h3>
              )}
              <ol>
                {coursesData[courseToShow]?.outline.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ol>

              <a
                className="button"
                href="https://forms.gle/yk3QhrrxtYQ8T5f49"
                target={"_blank"}
                rel="noreferrer"
              >
                Enroll Now
              </a>
            </div>
          )}
        </div>

        <div className="courses-hero-right">
          <div className="payment-div">
            <h3>Make Payment</h3>
            <p>
              We offer flexible payment options including m-pesa payment and
              card payment. For any assistance, feel free to reach out to our
              support team. Start your learning journey with ease and confidence
              at Code Rhythm!
            </p>
            <div className="payment-btns">
              <button className="button">Pay for online</button>
              <button className="button">Pay for Physical</button>
            </div>
          </div>
          {coursesData[courseToShow]?.requirements ? (
            <div className="enroll-div">
              <h3>Course Outline</h3>
              <ol>
                {coursesData[courseToShow]?.outline.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ol>

              <a
                className="button"
                href="https://forms.gle/yk3QhrrxtYQ8T5f49"
                target={"_blank"}
                rel="noreferrer"
              >
                Enroll Now
              </a>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}
