import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  const [isHome, setIsHome] = useState(true);
  useEffect(() => {
    window.location.pathname === "/" ? setIsHome(true) : setIsHome(false);
  }, []);
  return (
    <footer id="footer" className="footer">
      <div className="footer-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-info">
                <h3>Code Rhythm</h3>
                <p>
                  Nairobi <br />
                  Nairobi, Kenya
                  <br />
                  P. O. Box 10236 <br />
                  00200 Nairobi
                  <br />
                </p>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  {isHome ? <a href="#">Home</a> : <Link to="/">Home</Link>}
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  {isHome ? (
                    <a href="#about">About us</a>
                  ) : (
                    <Link to="/">About</Link>
                  )}
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  {!isHome ? (
                    <a href="#services">Courses</a>
                  ) : (
                    <Link to="/services">Courses</Link>
                  )}
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Socials</h4>
              <ul>
                <strong>Linkedin:</strong>{" "}
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/90924398"
                >
                  Code Rhythm
                </a>
                <br />
                <strong>Twitter:</strong>{" "}
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://x.com/code_rhythm"
                >
                  @code_rhythm
                </a>
                <br />
                <strong>Facebook:</strong>{" "}
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.facebook.com/profile.php?id=100091178066167"
                >
                  @code_rhythm
                </a>
                <br />
                <strong>Instagram:</strong>{" "}
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.instagram.com/code_rhythm/?next=%2F"
                >
                  @code_rhythm
                </a>
                <br />
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-newsletter">
              <h4>Contacts</h4>
              <strong>Phone:</strong> +254 703 780 424
              <br />
              <strong>Email:</strong> coderhythm@electrixitaty.com
            </div>
          </div>
        </div>
      </div>

      <div className="footer-legal text-center">
        <div className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
          <div className="d-flex flex-column align-items-center align-items-lg-start">
            <div className="copyright">
              &copy; Copyright
              <strong>
                <span>Code Rhythm</span>
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits">
              Designed by <a href="https://wansenziroz.com/">Lumona</a>
            </div>
          </div>

          <div className="social-links order-first order-lg-last mb-3 mb-lg-0">
            <a href="#" className="twitter">
              <i className="bi bi-twitter"></i>
            </a>
            <a href="#" className="facebook">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="#" className="instagram">
              <i className="bi bi-instagram"></i>
            </a>
            <a href="#" className="google-plus">
              <i className="bi bi-skype"></i>
            </a>
            <a href="#" className="linkedin">
              <i className="bi bi-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
