import React from "react";
import "./QuestionsSlide.css";

export default function QuestionsSlide({
  currentQuestion,
  question,
  choices,
  setQuestionNo,
  setDataChoices,
  setIsContactForm,
  dataChoices,
}) {
  const onSelectCourse = (index, choice) => {
    if (currentQuestion === 0) {
      setQuestionNo(index + 1);
      setDataChoices((prevChoices) => ({
        ...prevChoices,
        responses: {
          ...(prevChoices.responses || []),
          id: currentQuestion,
          question,
          choice,
        },
      }));
    } else {
      setDataChoices((prevChoices) => ({
        ...prevChoices,
        responses: {
          ...(prevChoices.responses || []),
          id: currentQuestion,
          question,
          choice,
        },
      }));
      setIsContactForm(true);
    }
  };
  console.log(dataChoices);

  return (
    <div className="questionsslide">
      <p className="question">{`${question}`}</p>
      {choices &&
        choices.map((item, index) => (
          <div
            onClick={() => onSelectCourse(index, Object.values(item)[0])}
            className="choice-div"
            key={index}
          >
            <div className="choice-number">
              <p style={{ margin: 0 }}> {`${Object.keys(item)[0]}`}</p>
            </div>
            <div className="choice" style={{ padding: 0 }}>
              <p style={{ margin: 0 }}>{`${Object.values(item)[0]}`}</p>
            </div>
          </div>
        ))}

      <div className="btns">
        {!choices && (
          <button className="continue" onClick={() => setIsContactForm(true)}>
            Continue
          </button>
        )}
        {currentQuestion > 0 && (
          <button
            className="back"
            onClick={() => setQuestionNo(currentQuestion - 1)}
          >
            Back
          </button>
        )}
      </div>
    </div>
  );
}
