import React from "react";
import "./LandingSlide.css";

export default function LandingSlide({ setIsLandingSlide }) {
  return (
    <div className="landingslide">
      <h2>
        Congratulations! Welcome to Code Rhythm. Click start to begin your
        enrolment.
      </h2>
      <h3>
        THE FIRST 2 CLASSES OF ANY COURSE ARE FREE. NO COMMITMENT IS REQUIRED
        PRIOR!
      </h3>
      <button onClick={() => setIsLandingSlide(false)}> START</button>
    </div>
  );
}
