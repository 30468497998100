import { Link } from "react-router-dom";
import "./Programme.css";

export default function Programme() {
  return (
    <section className="programme">
      <div className="programme-image"></div>
      <div className="programme-content">
        <h1 className="programme-category">Bootcamp</h1>
        <h2 className="programme-title">Summer Coding Camp</h2>
        <p className="programme-description">
          For kids aged 6 to 17, featuring hands-on projects in animation,
          Python programming, and dance.
        </p>
        <div className="programme-actions">
          <Link className="booknow-btn" to={"/payment/summercodingcamp"}>
            Book Now
          </Link>
          <a
            className="learnmore-btn"
            href="../SUMMER_CODING_CAMP_BROCHURE.pdf"
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Learn More
          </a>
        </div>
      </div>
    </section>
  );
}
