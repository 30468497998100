import React from "react";
import "./Home.css";

import Hero from "../../sections/hero/Hero";
import About from "../../sections/about/About";
import OfferedCourses from "../../sections/offeredcourses/OfferedCourses";
import WhyUs from "../../sections/whyus/WhyUs";

export default function Home() {
  return (
    <div className="home">
      <Hero />
      <OfferedCourses />
      <About />
      <WhyUs />
    </div>
  );
}
