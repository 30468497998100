export const questions = [
  {
    id: 0,
    question:
      "At Code Rhythm, we believe that coding is more than just lines of code - it's a pathway to personal growth and professional success. We're committed to revolutionizing tech education by integrating therapeutic principles into coding, helping individuals develop essential life skills such as critical thinking, decision-making, and problem-solving. Where would you like to start your journey?",
    choices: [
      {
        A: "Full Spectrum Tech Course (Roadmap to Professional Tech Expertise)",
      },
      { B: "Code DJ (Create And Mix Beats With Codes.)" },
      { C: "Specialised Tech Spectrum (Focused Learning Path)" },
      { D: "STEM Concept (Best for kids: Practical And Theoritical)" },
      { E: "Animation (Learn How To Create Both 2D and 3D Animation)" },
      { F: "Game Development (Start Building Games.)" },
      { G: "Language Mastering (C, Python, Javascript, C#, C++, R, Java)" },
    ],
  },
  {
    id: 1,
    question:
      "This track is tailored for individuals with specific interests or career aspirations within the tech industry. Students choose a specialized category, profession, or job type from our curated list and follow a focused learning path aligned with their chosen domain. Whether aspiring to be web developers, data analysts, machine learning engineers, or cybersecurity experts, students delve deep into relevant topics and acquire specialized skills to excel in their chosen field.\nChoose one of the below units you wish to pursue.",
    choices: [
      {
        A: "Web Developer: (25 classes)",
      },
      { B: "Mobile App Developer: (30 classes)" },
      { C: "Data Analyst/Scientist: (20 classes)" },
      { D: "Machine Learning Engineer: (40 classes)" },
      { E: "Cloud Architect: (15 classes)" },
      { F: "Cybersecurity Analyst/Engineer: (20 classes)" },
      { G: "UI/UX Designer: (20 classes)" },
      { H: "Full Stack Developer: (50 classes)" },
      { I: "Software Engineer: (40 classes)" },
    ],
  },
  {
    id: 2,
    question:
      "Unleash your creativity with Code DJ! This 4-week course combines beat making with coding, allowing you to create and mix beats using Python. Sharpen your problem-solving skills and discover new levels of creativity. By the end, you'll emerge as a proficient Code DJ, equipped with technical skills and a passion for music.\n\nDuration: 4 weeks\nWeek 1: Introduction to Beat Making\nWeek 2: Coding Beats Using Python\nWeek 3: Advanced Beat Mixing Techniques\nWeek 4: Project Showcase and Performance",
  },
  {
    id: 3,
    question:
      "This comprehensive track is designed for individuals seeking a well-rounded tech education journey. Students follow a structured roadmap, progressing from foundational concepts to specialized areas of expertise. Through a combination of interactive lectures, hands-on projects, and continuous learning, students gain proficiency in multiple tech domains, preparing them for diverse career paths within the industry.\nChoose your desired units you want to reach for structured learning from basics to more complex. Note that one unit is a build of the previous units, except the for the first unit. Also note that options are sequentially organized for structured learning.",
    choices: [
      {
        A: "Foundation in Programming Concepts and Web Development: (20 classes) 7 weeks",
      },
      { B: "Deep Dive into Frontend Development: (13 classes) 5 weeks" },
      {
        C: "Exploration of Backend Development and Databases: (5 weeks, 13 classes)",
      },
      { D: "Specialization in App Development: (5 weeks, 15 classes)" },
      { E: "Introduction to Cloud Architecture:(4 weeks, 10 classes)" },
      { F: "Data Analysis and Machine Learning: (5 weeks, 13 classes)" },
      { G: "Cybersecurity Fundamentals: (3 weeks, 7 classes)" },
      { H: "Prototyping and Design Principles: (3 weeks, 9 classes)" },
    ],
  },
  {
    id: 4,
    question:
      "Prepare your child for the future with STEM Concepts! Over 4 weeks, they'll explore electronics, 3D design, robotics, and renewable energy. Through hands-on experiments, they'll gain insights into science and technology while developing critical thinking and a growth mindset.\n\nDuration: 4 weeks",
    choices: [
      { A: "Introduction to Electronics" },
      { B: "3D Design and Printing" },
      { C: "Robotics and Automation" },
      { D: "Renewable Energy and Sustainability" },
    ],
  },
  {
    id: 5,
    question:
      "Enter the world of animation! Over 6 weeks, master 2D and 3D animation techniques. Develop storytelling skills and enhance your attention to detail. By the end, you'll have the skills and creativity to bring your ideas to life.\n\nWeek 1: Introduction to Animation Principles\nWeek 2: 2D Animation Techniques\nWeek 3: 3D Modeling Basics\nWeek 4: Rigging and Animation\nWeek 5: Lighting and Rendering\nWeek 6: Project Showcase and Feedback",
  },
  {
    id: 6,
    question:
      "Step into game development with Code Rhythm! Over 8 weeks, learn game design, programming, and production. Develop characters, gameplay mechanics, and storytelling abilities. Gain confidence and resilience to pursue a career in game development.\n\nWeek 1: Introduction to Game Development\nWeek 2: Game Design Principles\nWeek 3: Programming Basics for Games\nWeek 4: Graphics and Animation\nWeek 5: Sound and Music Integration\nWeek 6: Testing and Debugging\nWeek 7: Publishing and Distribution\nWeek 8: Game Showcase and Feedback",
  },
  {
    id: 7,
    question:
      "Discover the transformative potential of programming languages through our Language Mastering course! Immerse yourself in the complexities of Python, JavaScript, C#, C++, R, and Java, each offering 20 comprehensive classes. Acquire fluency in these languages, mastering syntax, data structures, and algorithms. Cultivate problem-solving abilities and foster logical thinking, crucial attributes for any budding coder. With engaging projects and expert mentorship, you'll forge a robust programming foundation, unlocking pathways to boundless opportunities in the tech realm.\n\nWhich language would you want to pursue first ?This question is required.",
    choices: [
      { A: "JAVASCRIPT" },
      { B: "PYTHON" },
      { C: "JAVA" },
      { D: "R" },
      { E: "C" },
      { F: "C#" },
      { G: "C++" },
    ],
  },
];
