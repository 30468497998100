import React from "react";
import "./About.css";

export default function About() {
  return (
    <section id="about" className="about">
      <div className="about-container">
        <a
          href="https://storyset.com/computer"
          target={"_blank"}
          rel="noreferrer"
        >
          <div className="about-image"></div>
        </a>
        <div className="about-content">
          <div className="about-section-title">
            <h2>About</h2>
            <h3>Unlock Your Potential with Code Rhythm</h3>
          </div>
          <p>
            Code Rhythm is an innovative tech educational platform dedicated to
            empowering learners of all ages with essential skills in technology
            and programming. At Code Rhythm, we believe in hands-on learning and
            practical application, where students immerse themselves in creating
            innovative projects from the ground up. Whether through courses,
            bootcamps, workshops, or webinars, our goal is to foster creativity,
            critical thinking, and technical proficiency in an engaging and
            supportive environment.
          </p>

          <p>
            Our innovative approach ensures that students not only gain
            technical expertise but also cultivate a sound mind and
            entrepreneurial mindset. Join us and unlock your full potential with
            Code Rhythm.
          </p>
          <a
            href="https://forms.gle/yk3QhrrxtYQ8T5f49"
            target={"_blank"}
            rel="noreferrer"
            className="button"
          >
            Enroll Now
          </a>
        </div>
      </div>
    </section>
  );
}
