import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Import Firebase Storage

import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCae9ANRDQx2YqLU6FI28DUxjml5g4oijA",
//   authDomain: "code-rhythm-enrollment.firebaseapp.com",
//   projectId: "code-rhythm-enrollment",
//   storageBucket: "code-rhythm-enrollment.appspot.com",
//   messagingSenderId: "191660238195",
//   appId: "1:191660238195:web:fb1c0c46edfae8b1965106",
//   measurementId: "G-DS07DL8QT3",
// };

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const database = getFirestore();
export const db = getDatabase(app); // Initialize the Realtime Database
export const storage = getStorage(app); // Initialize Firebase Storage
